.article-selector {
  text-align: center;
  .dropdown-item {
    display: inline-block;
    width: auto;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
    margin: 0.2em;
    .asl-col-2, .asl-col-3 {
      display: inline-block;
      margin: 0 0.3em 0 0;
    }
    .asl-col-2::after{
      content :" /";
    }
    img {
      max-height: 100px;
    }
  }
}

.typeahead-input-group .btn {
  margin-top: -1px;
  padding-bottom: 8px;
}


.articles-gallery .carousel-inner {
  height: 54vh;
}
.articles-gallery .carousel-item {
  height: 50vh;
  background: none;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  position: inherit;
}
.articles-gallery .carousel-item img {
  max-width: 100%;
  max-height: 100%;
}
.articles-gallery .carousel-caption {
  color: white;
  background: #3083dcdd;
  border-radius: 0.5em;
  padding: 1em 1em 1.6em;
  text-align: left;
  bottom: -6%;
}
.articles-gallery .carousel-indicators button {
  background-color: black;
}


.article-image-description {
  font-size: 0.8em;
}


.article-form-container {
  background: #fff;
  padding: 0.8em;
  border-radius: 0.4em;
  box-shadow: 3px 3px 3px rgba(0,0,0,0.2);
  border: 1px solid #aaa;
  margin: 0 0 1em 0;
  position: relative;
}

.articles-form-mini .article-form-container,
.article-form-container-mini {
  padding: 0;
  border-radius: 0;
  border: 0;
  box-shadow: none;
  margin: 1em 0 0 0;
  position: relative;
  font-size: 0.8em;
}


.article-edit-controls {
  position: absolute;
  right: 0.2em;
  top: 0.2em;
}
