.bill-form-group {
  padding-bottom: 3em;
}

.bill-preview-box {
  background: #fff;
  padding: 2em;
  h1, h2, h3, h4, h5 {
    color: #222
  }
}
