/*.thead {
      overflow-y: auto;
      overflow-x: hidden;
    }

    .tbody {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 500px;
    }
*/

body {
  overflow: auto;
  padding: 1em;
}

.pagination {
  text-align: center;
  display: block;
  margin: 25px auto 10px;

  & > li {
    display: inline-block;
  }
  .form-control {
    display: inline-block;
  }
}

.table {
  max-height: max-content;
  position: relative;
  min-width: min-content !important;
//  overflow : auto;


  .btn-link {
    text-decoration: none;
  }
}

.td {
  padding: 0.3rem 0.4rem;
  background: #fff;
}
.th {
  background: #fff;
}

input[type=range] {
  max-width: 100%;
}

.thead {
  position: sticky;
  display: block;
  top: 0;
  z-index: 40;
  min-height : min-content;
  min-width: min-content;
  border-bottom: 2px solid #222;
  .align-center {text-align: center;}
  .align-right {text-align: right;}
  .align-left {text-align: left;}
  .align-justify {text-align: justify;;}
}
.tbody {
  min-width: min-content;
}

.tfoot {
  font-weight: 700;
}



.col-shadow:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 20px;
  background: linear-gradient(to right, rgba(31,148,231,0.3) 0%,rgba(31,148,231,0.1) 50%, transparent);
  margin-left: 1px;
  opacity: 0.6;
}

.td:first-child, .th:first-child, .col-sticky {
  position: sticky;
  left: 0;
  //box-shadow: 5px 3px 3px 0px rgba(48, 148, 231, 0.2);
}
.td:first-child {
  z-index: 30;
}
.th:first-child {
  z-index: 50;
}
