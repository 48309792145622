.table-viewer-features {
  margin: 0 0 0.3em 0;
  text-align: right;
  button {
    margin: 0 0 0 1em;
  }
}

.table-viewer-select {
  margin-bottom: 0.4em;
}

.table-viewer-info {
  color: white;
  font-size: 0.8em;
  line-height: 1em;
}

.table-viewer-modified {
  display: inline-block;
  vertical-align: baseline;
  text-align: right;
  width: 100%;
  padding-top: 15px;
}
