$sidebar-expanded-width: 160px;
$sidebar-collapsed-width: 36px;
$sidebar-popout-width: 140px;
$sidebar-background-color: rgba(0, 0, 0, 0.8);


.plotly-modal {
  padding: 0 0 0 $sidebar-collapsed-width;
}
.td.col-shadow,
.th.col-shadow {
  padding-left: ($sidebar-collapsed-width + 10) !important;
}



.sidebar {
  overflow: visible;
  height: 100vh;
  width: $sidebar-expanded-width;
  position: fixed;
  z-index: 9999;
  // background: rgba(255, 255, 255, 0.9);
  background: $sidebar-background-color;
  color: #fff;
  padding: 2.3em 0em 0.8em 0em;
  margin: 0;
  left: 0;
  top: 0;


  ul {
   margin: 0 0 1em 0;
   padding: 0;
   list-style: none;
  }

  li {
    display: inline-block;
    width: 100%;
    padding: 0.1em 1em 0.2em 1em;
    border-width: 1px 0px 0px 0px;
    border-color: rgba(255,255,255,0.1);
    border-style: solid;
    text-align: left;
    span {
      display: block;
      float:left;
    }
    a:link {
      display: block;
      text-align: center;
    }
    &:hover a:link,
    &:hover a:visited,
     {
      color: #fff;
    }
  }


  &.sidebar-collapsed {
    width: $sidebar-collapsed-width;
    padding: 0 0 0 0;

    ul {
      margin: 0;
    }

    li {
      padding: 0.2em 0.6em;

      // &:hover a:link {
      &:hover a:link {
        text-indent: 0;
        position: absolute;
        width: $sidebar-popout-width;
        padding: 0.1em 0.1em 0.1em  $sidebar-collapsed-width;
        // padding-left: $sidebar-collapsed-width;
        text-align: left;
        color: $sidebar-background-color;
        height: auto;
      }
    }

    a:link {
      text-indent: -99999px;
      position: absolute;
    }


    .sidebar-group {
      border: 0;
    }

    .sidebar-group-header,
    .sidebarRPALogo {
      display: none;
    }

    .sidebar-toggle {
      position: initial;
      padding: 0 0.6em 0.6em 0.6em;
      display: block;
    }

  }

}

.sidebar-toggle {
  position: absolute;
  right: 0.3em;
  top: 0.3em;
  cursor: pointer;
}

.sidebar-group-header {
  font-size: 0.6em;
  text-transform: uppercase;
  padding: 0em 0 0 1.6em;
  text-align: center;
}
.sidebar-group {
  border-width: 0px 0px 1px 0px;
  border-color: rgba(255,255,255,0.1);
  border-style: solid;
}

.sidebarRPALogo {
  max-width: 90%;
  margin: 5%;
}

.container-sidebar-expanded {
  transition: 0.3s ease-out padding-left;
  padding-left: $sidebar-expanded-width;
}
.container-sidebar-collapsed {
  transition: 0.3s ease-out padding-left;
  padding-left: $sidebar-collapsed-width;
}
