.entity-tags {
  display: inline-block;
  input {
    font-size: 0.6em;
    font-weight: normal;
    display: inline-block;
    background: none;
    border: 1px solid rgba(255,255,255,0);
    border-width: 0 0 1px 0;
    color: #fff;
    width: 2em;
  }
  input:hover {
    background: rgba(255,255,255,0.1);
  }
  input:focus {
    border-color: rgba(255,255,255,1);
    width: 8em;
  }
}

.tag-icon,
.tag-icon:link,
.tag-icon:visited,
.tag-icon:active,
.tag-icon:hover, {
  color: #fff;
  margin-right: 0.3em;
  cursor: pointer;
}

.tag-list {
  list-style: none;
  padding: 0 0 0.1em 0;
  margin: 0;
  font-size: 0.6em;
  font-weight: normal;
  display: inline-block;
  li {
    display: inline-block;
    margin: 0 0.3rem 0.2rem 0
  }

}


.tag-highlight {
  animation: tag-highlight 1s linear infinite;
  animation-iteration-count: 2;
}

@keyframes tag-highlight {
  50% {
    opacity: 0;
  }
}
