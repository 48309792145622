.table .btn-link,
table .btn-link {
  padding: 0;
}

.table .btn-link {
  font-size: inherit;
  padding: 0;
}

.value-date {
  display:none;
  color: black;
  &:after {
    content: ": ";
  }
}


.nested-depth-2 > .td {
  background: #efefef;
  padding: 0.1rem 0.7rem;
  font-size: 0.9em;
  .value-date {
    display: inline-block;
    float: left;

  }
}
.nested-depth-2 {
  border-top: 1px solid #dedede;
}
.col-sticky.col-shadow {
  background: #fff;
}
