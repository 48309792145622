
.ida-entries {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
}
.ida-entry {
  padding: 0.6em 1.4em 0.6em 3.4em;
  margin: -1px 0 0 0;
  display: flex;
  border: 1px solid #efefef;
  border-width: 1px 0px 1px 0px;

  background-size: 2em 2em;
  background-repeat: no-repeat;
  background-clip: border-box;
  background-position: 1em 5px;
  & > span {
    cursor: pointer;
    flex: 1 1;
  }
}
.ida-accordion {
  label, label.is-disabled {
    display: block;
    cursor: auto;
    border: none;
  }
  .accordion-header {
    label {
      margin-left: auto;
    }

    .currency-pa {
      font-size: 0.7em;
    }
    .ida-target {
      // float: right;
      width: auto;
      font-size: 0.9em;
      text-align: right;
      padding-right: 0.5em;

    }
  }
  .accordion-body {
    margin: 0;
    padding: 0;
  }
}

.ida-entry-controls > * {
  margin: 0 0.3em;
}
.ida-item-select .currency-pa {
  display: none;
}

.product-thumbnail {
  width: 100%;
  min-height: 300px;
  background-clip: content-box;
  background-position: 50% 50%;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
}
.product-suggestion-thumbnail {
  width: 100%;
  min-height: 100px;
  background-clip: content-box;
  background-position: 0% 50%;
  background-size: 100px;
  background-repeat: no-repeat;
  background-image: url('/style/icons/flower1.svg');
  display: inline-block;
  position: relative;
  span {
    position: absolute;
    top:100%;
    background: white;
  }
}

.product-suggestion label {
  display: inline-block;
  width: 100%;
}


.confirm-product-modal .modal-header {
  font-size: 1.6em;
}


.ida-entry .article-size {
  opacity: 0.6;
}


.ida-controls {
  padding: 1em;
  display: flex;
}
