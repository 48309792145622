
.link-cell {
  width: 100%;
  display: block;
  &:hover .product-thumbnails {
    visibility: visible;
  }

  .product-thumbnails {
    display: inline-block;
    position: relative;
    visibility: hidden;
    img {
      display: inline-block;
      max-height: 5em;
      position: absolute;
      vertical-align: middle;
      bottom: -1.2em;
      margin-left: 1em;
      border: 0.2em solid #dedede;
    }
  }
}

.rbt-close-content {
  display: none;
}

.form-range-labels > li {
  &:first-child {
    border-left: 0;
  }
  width: 30px;
  text-align: left;
  border-left: 1px solid white;
  position: absolute;
  top: 0;
  height: 44.9px;
  vertical-align: middle;
}
.form-range-labels {
  height: 79px;
  margin: 0;
  padding: 0;
  list-style: none;
  color: white;
  font-size: 0.8em;
  position: relative;
}
.form-range-labels > li > span {
  transform: translateY(15px) rotate(90deg);
  display: block;
  width: 44px;
  margin: 0px -5px;
}
