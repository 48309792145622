$thumbnail-width : 200px;
$thumbnail-height : 200px;
$thumbnail-margin : 6px;


.image-dropper {
  padding: 0 0 1em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  // display: block;
  color: #fff;
  position: relative;
}
.image-dropper img {
  max-width: 270px;
  max-height: 270px;
  display: inline-block;
}

.image-dropper-target {
  width: $thumbnail-width;
  height: $thumbnail-height;
  border: 2px dashed #eee;
  border-radius: 0.6em;
  padding: 1em;
  display: inline-block;
  margin: $thumbnail-margin;
  cursor: pointer;
}

.image-dropper-thumbnail-controls {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(0,0,0,0.4);
  padding: 0.4em 0.4em;
  line-height: 0;
  visibility: hidden;
  *{
    cursor: pointer;
  }
}
.image-dropper-thumbnail-alt {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: auto;
  text-align: center;
  padding: 1em;
  color: #fff;
  visibility: hidden;
  .form-control {
    text-align: center;
    border: 1px solid rgba(255,255,255,1);
    font-size: 0.8em;
  }
  &:focus-within {
    visibility: visible;
  }
}
.image-dropper-thumbnail,
.image-dropper-added {
  width: $thumbnail-width;
  height: $thumbnail-height;
  display: inline-block;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  margin:$thumbnail-margin;
  box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
  position: relative;
  transition: opacity ease-out 0.2s;
  &:hover .image-dropper-thumbnail-controls,
  &:hover .image-dropper-thumbnail-alt {
    visibility: visible;
  }
  &.dragged {
    z-index:9999;
  }
}

.image-dropper-deleted {
  opacity: 0.3;
}
.image-dropper-added {
  background: rgba(0,0,0,0);
  padding: 1em;
  display: inline-block;
}

.placeholder {
  vertical-align: top !important;
}
