.product-selector {
  text-align: center;

  .dropdown-menu {
    max-height: 50vh;
  }
  .dropdown-item {
    display: inline-block;
    width: auto;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
    margin: 0.2em;
    color : black;
    padding: 0;
    height: auto;
    text-align: center;
    padding: 0.4em;

    .asl-col-2, .asl-col-3 {
      display: inline-block;
      margin: 0 0.3em 0 0;
    }
    .asl-col-2::after{
      content :" /";
    }
    img {
      max-height: 100px;
    }
  }
}

.typeahead-input-group .btn {
  margin-top: -1px;
  padding-bottom: 8px;
}
