@import './style/colors.scss';
@import './style/Dashboard.scss';
@import './style/Table.scss';
@import './style/TableViewer.scss';
@import './style/FlexTable.scss';
@import './style/sidebar.scss';
@import './style/Modal.scss';
@import './style/ImageDropper.scss';
@import './style/ImageDropperAccordion.scss';
@import './style/ProductsOverview.scss';
@import './style/EntityTags.scss';
@import './style/Typeahead.scss';
@import './style/Mapbox.scss';
@import './style/Articles.scss';
@import './style/Products.scss';
@import './style/Bills.scss';
@import './node_modules/react-bootstrap-typeahead/css/Typeahead.scss';

/* COLORS from https://coolors.co/
$light-yellow: #f6fedbff;
$wheat: #e6d3a3ff;
$straw: #d8d174ff;
$middle-green-yellow: #b6c454ff;
$citron: #91972aff;
*/




a:link, a:visited, a:active, a:hover {
  color: $active;
  text-decoration: none;
}

.numbers-red {
  color: $warning;
}

button a:link,
button a:visited,
button a:active,
button a:hover {
  color: #fff;
}

h1, h1 a:link, h1 a:visited, h1 a:active, h1 a:hover {
  text-decoration: none;
  color: $white;
  text-align: center;
  font-weight: 610;
  img {
    display: inline-block;
    margin: 0 auto;
    vertical-align: bottom;
  }
}

.delete {
  opacity : 0.5;
  transition: opacity 0.3s ease;
}

h1 {
  padding: 0.3em 0 0.8em;
  margin-bottom: 0.3em;
  text-align: center;
}

h2  {
  color: $white;
}

h3  {
  color: $white;
  margin: 0.7em 0 0.4em;
}

h4  {
  font-size: 1.2em;
  color: $white;
  margin: 0.7em 0 0.4em;
}

table, .table {
  margin: 0px;
  background: $front;
  border: 1px solid #ddd;
  //overflow-x: auto;
  //padding-bottom: 5em;
  //height: 60vh;
  &.table-loading {
    opacity: 0.5;
  }

  .dropdown {
  //  text-align: right;
  }

  .nested-row {
    font-size: 0.8em;
  }
}

.signout-button {
  position: fixed;
  top : 0;
  right: 0;
  margin: 0.5em;
}


.preload-progress {
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
  background: #000;
  transition: ease all 1s;
  max-width: 100vw;
  z-index: 999999;
}

.manageTable {

  td {
    padding: 0.5em;
    border: 1px solid;
    border-color: #ddd #f5f5f5;
    border-width: 1px 1px 0 0;
  }
  tr td:last-child,
  tr th:last-child {
    //text-align: right;
    a, button {
      margin: 0 0 0 0.3em;
    }
  }

  tbody td,
  th {
    padding: 0.5em 2.4em 0.5em 1.2em;
  }
}


thead th, .th {
  padding: 4px 15px;
  text-transform: uppercase;
  font-weight: normal;

  &:first-child {
    padding-left: 21px;
  }

  a:link {
    text-decoration: none;
  }

  select {
    width: 100%;
  }
}

thead, .thead {
  background: $front;
//  border-bottom: 4px solid $middle-green-yellow;
}



tbody > tr > td, .td {
  background: $front ;
  border: 1px solid #ddd;
  border-width: 0 1px 0 0;

  &:first-child {
    padding-left: 21px;
  }


}

body {
  background: $back;
  font-size: 1em !important;
  padding: 1em;
}

.login-wrapper, .reset-wrapper {
  h1,h2,h3,h4,h5 {
    color: black;
    text-align: center;
    margin: 0 0 1em 0;
  }
  width: 25vw;
  min-width: 320px;
  margin: 20vh auto;
  background: $white;
  padding: 2em;
  border-radius: 0.3em;
  text-align: right;
  .btn {
    margin: 1.5em 0 0;
  }
  .alert {
    margin: 0em 0 1em 0;
  }
  div {
    text-align: left;
  }

  img {
    max-width: 100%;
  }
}

nav {
  background: none;
  a:link, a:active, a:visited {
    color: $white;
    text-decoration: none;
    display: inline-block;
    margin: 0 0.3em 0 0;
    padding: 0.2em 0.3em;
  }
  a:hover {
    background: rgba(0,0,0,0.1);
  }
}

.form-container {
  width : 100%;
}

.modal-body .btn {
  margin: 1em 0 0 0;
}


.plotly-modal {
  min-width: 80vw;
}

.topClientLogo {
  max-height: 100px;
  border-radius: 0.2em;
  background: white;
  padding: 0.2em;
  margin-right: 0.6em;
}
.topRPALogo {
  max-width: 230px;
  display: none;
}
.topRPALogoSmall {
  display: block;
  max-width: 300px;
}



.topFilter {
  margin: 0 0 0.3em 0;
  padding: 0;
  list-style: none;
  li {
    display: inline-block;
    margin: 0 1em 0 0;
  }
  .btn-link {
    color: #fff;
    text-decoration: none;
  }
}


.addPermissions {
  padding: 0;
  li {
    margin: 0.3em;
    display: inline-block;
  }
}

.btn {
  margin: 0.2em 0;
}

.btn.btn-secondary {
  background: white;
  color: #111;
  opacity: 9.2;
  &:hover { opacity: 1}
}

select {
//  width: 150px;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  border: 1px solid #CCC;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(https://stackoverflow.com/favicon.ico) 96% / 15% no-repeat #EEE;
}


/* Using SCSS variables to store breakpoints */
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
@media (min-width: $breakpoint-tablet) {
  .form-container {
    width : 50vw;
  }

  .manageTable {


    tbody td,
    th {
      padding: 0.3em 1.6em 0.5em 0.9em;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .login-wrapper {
    min-width: 600px;
  }

  .topRPALogo {
    display: inline-block;
  }
  .topRPALogoSmall {
    display: none;
  }


}

@media (min-width: $breakpoint-desktop) {
  .form-container {
    width : 40vw;
  }
  .login-wrapper {
    min-width: 800px;
  }

}

/* CAUTION: Internet Explorer hackery ahead */


select::-ms-expand {
    display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}

/* Target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
    select {
        background: none\9;
        padding: 5px\9;
    }
}
